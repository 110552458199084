@value colors: "../../../../../components/library/style/colors.css";
@value color-gray-light, color-purple-gray, color-error from colors;

.sumup {
    padding: 0 24px;
    border-top: 1px solid color-gray-light;
}

.row {
    display: grid;
    grid-template-columns: 2.25fr 1fr 1fr 4.25fr;
    align-items: center;
}

.label {
    padding-right: 24px;
    text-align: right;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
}

.result {
    background-color: color-purple-gray;
    border-radius: 5px;
}

.result button {
    max-width: 300px;
}

.balance {
    font-weight: 500;
    font-size: 18px;
    margin: 0;
    color: color-error;
}

.button {
    display: none;
}

@media screen and (max-width: 768px) {
    .hide {
        display: none;
    }

    .sumup {
        padding: 0;
    }

    .row {
        grid-template-columns: 2fr 1fr;
    }

    .button {
        display: block;
        width: 100%;
        max-width: unset !important;
    }

    .result {
        padding: 4px;
        margin-bottom: 64px;
    }
}
