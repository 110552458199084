@value colors: "../../../../../../../components/library/style/colors.css";
@value color-purple-bg, color-gray from colors;


.products {
    padding: 8px;
    border-radius: 5px;
    background: #F8F9FB;

    display: flex;
    flex-direction: column;
    row-gap: 16px;

    margin-bottom: 8px;
}

.products .title, .products .row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;
}

.title {
    font-weight: 500;
}

.products p {
    margin: 0;
}
