@value colors: "../../../../components/library/style/colors.css";
@value color-text-subtle from colors;

.error {
    height: 35vh;

    display: flex;
    justify-content: center;
    align-items: center;
}

.error p {
    font-weight: 500;
    font-size: 24px;
    color: color-text-subtle;
}
