@value colors: "../../../components/library/style/colors.css";
@value color-text-subtle from colors;

.table > div {
    display: grid;
    grid-template-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.25fr;
    align-items: center;
}

.table {
    margin-top: 48px;
}

.toolbar {
    padding: revert;
    margin-top: 24px;
}

.error {
    height: 35vh;

    display: flex;
    justify-content: center;
    align-items: center;
}

.error p {
    font-weight: 500;
    font-size: 24px;
    color: color-text-subtle;
}


@media screen and (max-width: 768px) {
    .table {
        margin-top: 16px;
    }
}
