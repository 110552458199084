@value colors: "../../../../../components/library/style/colors.css";
@value color-text-subtle from colors;

.table {
    padding: 24px;
}

.table > div {
    display: grid;
    grid-template-columns: 0.25fr 1fr 2fr 1fr 1fr 1fr 1fr 0.25fr;
}

.table > div > p {
    overflow: hidden;
    text-overflow: ellipsis;
}

.empty {
    height: 35vh;

    display: flex;
    justify-content: center;
    align-items: center;
}

.empty p {
    font-weight: 500;
    font-size: 36px;
    color: color-text-subtle;
}

@media screen and (max-width: 768px) {
    .table {
    padding: 0;
}
}
