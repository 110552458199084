@value colors: "../../../../../../../components/library/style/colors.css";
@value color-gray, color-gray-light, color-purple-bg, color-purple, color-text-subtle from colors;

.dropdown {
    position: absolute;
    right: 0;
    top: 48px;
    z-index: 1000;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    background-color: white;
    border-radius: 5px;
    border: 1px solid color-gray-light;

    padding: 8px 0;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
}

.dropdown button {
    height: 48px;
    padding: 0 16px;

    font-weight: 500;
    text-align: left;

    display: flex;
    align-items: center;
}

.dropdown button:hover {
    background-color: #F7F8FF;
    color: color-purple;
    font-weight: 500;
}

.dropdown button:disabled {
    color: color-text-subtle;
}

.dropdown button:disabled:hover {
    background-color: white;
}

.icon {
    margin-right: 16px;
    margin-left: 8px;
}

@media screen and (max-width: 768px) {
    .dropdown {
        bottom: 48px;
    }
}
