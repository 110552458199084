@value colors: "../../../../components/library/style/colors.css";
@value color-text-subtle, color-red-bg, color-error from colors;

.empty {
    width: 100%;
    min-height: 150px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;

    font-size: 24px;
    font-weight: 500;
    color: color-text-subtle;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    overflow: auto;
}

.refundRow {
    margin: 8px 0;
}

.button {
    width: 49%;
    align-self: center;
}

.hideOverflow {
    overflow: hidden;
    text-overflow: ellipsis;
}

.background {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(255, 255, 255, 0.75);
}

.modal {
    background-color: white;
    border-radius: 5px;
    padding: 16px;
    box-shadow: 0 4px 15px rgba(59, 77, 181, 0.16);
}


.footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.footer button + button {
    margin-left: 16px;
}

.warning {
    margin: 16px auto auto;

    width: 54px;
    height: 54px;
    border-radius: 72px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: color-red-bg;
}

.warning .icon {
    color: color-error;
}

.text {
    font-size: 18px;
    max-width: 450px;
    text-align: center;
}

.text b {
    margin: 0 4px;
}
