.container {
    position: fixed;
    top: 0;
    left: 250px;

    width: calc(100% - 250px);
    height: 100vh;
    background-color: #000000c9;
    overflow-y: scroll;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.container.customer {
    width: 100%;
    left: 0;
}

.header {
    width: 100%;

    position: sticky;
    top: 0;
    z-index: 100;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    color: white;
    background: linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0) 100%);

    padding: 16px 24px;
    box-sizing: border-box;
}

.titleContainer {
    display: flex;
    flex-direction: row;
}

.titleContainer h1 {
    font-size: 16px;
    font-family: Roboto;
    font-weight: 400;

    margin-left: 16px;
}

.pagination {
    position: fixed;
    bottom: 24px;
    right: 37%;

    background-color: #000000b5;
    color: white;
    border-radius: 5px;
    padding: 0 24px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin: auto;
}

.number {
    border-right: 1px solid white;
    padding-right: 16px;
    margin-right: 16px;
}

.disabled {
    color: #ffffff8c;
}

.link {
    color: white;
}

.link:hover {
    color: #ffffffbf;
}

.document {
    margin-bottom: 88px;
}

.imageContainer, .preview {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.preview p {
    color: white;
    padding: 8px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 36px;
}

.error {
    height: 80vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: #bdbdbd;
}

.error h1 {
    margin: 0;
    font-size: 36px;
}

.error .icon {
    font-size: 82px;
    margin-bottom: 16px;
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
  }
}
