.table {
    padding: 24px;
    width: 100%;
    min-height: 57vh;
    box-sizing: border-box;
    scrollbar-color: rgba(0, 0, 0, 0.1) transparent; /* thumb and track color */
    scrollbar-width: thin;
}

.table::-webkit-scrollbar {
    height: 4px;  /* for vertical scrollbars */
}

.table::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.table > div {
    display: grid;
    grid-template-columns: 0.25fr 1fr 1fr 1fr 1fr 1fr 0.25fr;
}

.table > div > p {
    overflow: hidden;
    text-overflow: ellipsis;
}

@media screen and (max-width: 768px) {
    .table {
        padding: 0;
        min-height: unset;
    }
}
