.toolbar {
    padding: 0;
    margin-top: 24px;
    margin-bottom: 24px;
}

.table > div {
    display: grid;
    grid-template-columns: 0.25fr 1fr 1fr 1fr 1fr 0.25fr;
}
