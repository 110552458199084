@value colors: "../library/style/colors.css";
@value color-gray, color-text-subtle, color-purple from colors;

.footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    margin: 24px;
    padding: 8px 16px;
    border-radius: 5px;

    background-color: #F8F9FB;
    color: color-text-subtle;
}

.pages {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.description {
    margin-right: 8px;
}

.input {
    width: 32px;
    height: 32px;
    border: 1px solid color-gray;
    border-radius: 5px;
    outline: none;
    text-align: center;
}

.activePage {
    width: 32px;
    height: 32px;

    background-color: white;
    border: 1px solid color-gray;
    border-radius: 5px;

    font-weight: 400;
}

.arrowButton.left {
    margin-left: 32px;
}

.arrowButton.right {
    margin-left: 4px;
}

.arrowButton {
    background-color: white;
    border: 1px solid color-gray;
    border-radius: 5px;

    width: 32px;
    height: 32px;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: .3s;
}

.arrowButton:hover {
    color: white;
    background-color: color-purple;
}

.icon {
    font-size: 16px !important;
}

@media screen and (max-width: 768px) {
    .footer {
        margin: 0;
    }

    .footer > p {
        margin: 0;
    }

    .arrowButton.left {
        margin-left: 8px;
    }
}
