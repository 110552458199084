@value colors: "../../../../components/library/style/colors.css";
@value color-error-light, color-error-dark, color-success-light, color-success-dark from colors;

.row {
    position: relative;
}

.row .title { display: none; }

.status {
    width: max-content;
    border-radius: 5px;
    padding: 0 8px;
    font-weight: 500;
}

.wait {
    background-color: color-error-light;
    color: color-error-dark;
}

.paid {
    background-color: color-success-light;
    color: color-success-dark;
}

.row .title {
    display: none;
}

@media screen and (max-width: 768px) {
    .row .title {
        display: block;
    }

    .row {
        display: grid;
        grid-template-columns: repeat(2, 1fr) !important;
        margin-bottom: 16px;
        padding: 8px;
        box-sizing: border-box;

        background-color: #F8F9FB;
        border-radius: 5px;
    }

    .row p {
        margin: 4px 0;
    }

    .row > div {
        margin-bottom: 8px;
    }

    .reference {
        font-weight: 500;
        font-size: 18px;
        white-space: nowrap;
        margin: 16px 0;
    }

    .more {
        text-align: right;
    }
}
