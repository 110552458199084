@value colors: "../library/style/colors.css";
@value color-purple, color-success, color-success-light, color-error from colors;

.label {
    font-weight: 500;
}

.cancel {
    margin-top: 8px;
}

.balance {
    margin-left: 4px;
    color: color-purple;
}

.success {
    margin-left: 8px;
    padding: 4px 8px;
    border-radius: 5px;

    font-weight: 500;
    font-size: 14px;

    color: #0cbf84;
    background-color: #e4fbf0;
}

.error {
    color: color-error;
    font-size: 14px;
    margin-top: 0;
    font-style: italic;
}
