@value colors: "../library/style/colors.css";
@value color-text-subtle, color-error-light, color-error-dark from colors;

.header {
    margin-bottom: 24px;
}

.custom {
    padding: 0;
    width: unset !important;
    min-width: unset;
    color: color-text-subtle;

    margin-left: auto;
}

.url {
    background-color: #F8F9FB;
    padding: 16px 8px;
    border-radius: 5px;
    margin-top: 0;
    margin-bottom: 48px;
}

.error {
    display: flex;
    align-items: center;

    background-color: color-error-light;
    color: color-error-dark;
    padding: 8px;
    border-radius: 5px;
}

.error .icon {
    margin-right: 8px;
}

.loading > div {
    height: 25vh;
}
