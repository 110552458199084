@value colors: "../library/style/colors.css";
@value color-text-subtle from colors;

.background {
    position: fixed;
    top: 0;
    left: 250px;

    width: calc(100% - 250px);
    height: 100vh;
    background: rgba(246, 246, 251, 0.70);

    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    min-width: 250px;
    padding: 36px 24px;

    background-color: white;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.modal header h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    text-align: center;

    margin: 0;
}

.modal header p {
    font-family: Roboto;
    font-style: normal;
    font-size: 16px;
    text-align: center;
    color: color-text-subtle;
    margin-top: 8px;
}

.modal button {
    width: 100%;
}

.fullsize {
    width: 100%;
    left: 0;
}
