@value colors: "../library/style/colors.css";
@value color-purple-gray, color-text-subtle, color-purple, color-gray from colors;

.project {
    margin-left: 250px;
    width: calc(100% - 250px);
}

.header {
    width: 100%;
    padding: 32px 24px 0 24px;;
    box-sizing: border-box;
    background-color: color-purple-gray;
}

.subtitle {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    color: #4F67F3;

    margin: 0;
}

.title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    margin-top: 4px;
    margin-bottom: 24px;
}

.button {
    height: 48px;
    padding: 0;
    font-weight: 500;
    border-bottom: 3px solid color-purple-gray;
    color: color-text-subtle;
    transition: .3s;
}

.selected, .button:hover {
    color: black;
    border-bottom: 3px solid color-purple;
}

.button + .button {
    margin-left: 24px;
}

.error {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.error .title {
    margin-bottom: 0;
}

.searchbar {
    display: flex;
    align-items: center;

    margin-bottom: 16px;
    width: 100%;
    height: 48px;
    padding: 8px;
    box-sizing: border-box;

    border-radius: 5px;
    background-color: white;
    border: 1px solid color-gray;
}

.searchbar .icon {
    color: color-text-subtle;
    margin-right: 8px;
}

.searchbar input {
    border: none;
    outline: none;

    width: 100%;
}

.link {
    font-weight: 500;
    text-decoration: none;
    color: black;

    display: flex;
    align-items: center;

    transition: .3s;
    margin-bottom: 24px;
}

.link:hover {
    color: color-purple;
}

.link span {
    margin-left: 8px;
}

@media screen and (max-width: 1024px) {
    .project {
        margin-left: 0;
        width: 100%;
        box-sizing: border-box;
    }
}
