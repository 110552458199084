@value colors: "../../../../../../components/library/style/colors.css";
@value color-purple, color-success-dark, color-success-light, color-error-dark, color-error-light, color-purple-bg, color-purple-dark from colors;

.status {
    font-weight: 500;
    text-transform: Capitalize;

    width: fit-content;
    padding: 0 16px;
    border-radius: 5px;
}

.status.paid, .status.succeeded {
    color: color-success-dark;
    background-color: color-success-light;
}

.status.wait, .status.refunded {
    background-color: color-error-light;
    color: color-error-dark;
}

.status.request {
    background-color: color-purple-bg;
    color: color-purple-dark;
}

.row {
    position: relative;
}

.wait {
    color: color-error-dark;
    font-weight: 500;
}

.row .title {
    display: none;
}

.button {
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    padding: 0;
    transition: .3s;
}

.button:hover {
    color: color-purple;
}

@media screen and (max-width: 768px) {
    .row .title {
        display: block;
    }

    .row {
        display: grid;
        grid-template-columns: repeat(2, 1fr) !important;
        margin-bottom: 16px;
        padding: 8px;
        box-sizing: border-box;

        background-color: #F8F9FB;
        border-radius: 5px;
    }

    .row p {
        margin: 4px 0;
    }

    .row > div {
        margin-bottom: 8px;
    }

    .reference {
        font-weight: 500;
        font-size: 18px;
        white-space: nowrap;
        margin: 16px 0;
    }

    .more {
        text-align: right;
    }
}
