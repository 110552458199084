@value colors: "../library/style/colors.css";
@value color-purple-gray, color-text-subtle, color-purple, color-gray, color-gray-light from colors;

.toolbar {
    padding: 24px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.searchbar {
    display: flex;
    align-items: center;

    border: 1px solid color-gray;
    border-radius: 5px;
    width: 40%;
    margin-right: 8px;
    min-width: 250px;
    padding: 0 8px;
}

.searchbar input {
    border: none;
    outline: none;
    width: 100%;
}

.toolbar .custom {
    color: color-text-subtle;
    border: 1px solid color-gray;
    font-weight: 500;
}

.toolbar .custom:hover {
    background-color: color-gray-light;
}

.toolbar .custom:disabled:hover {
    background-color: #C0BFCF;
}

.icon {
    color: color-text-subtle;
}

.row {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
}

.row button + button {
    margin-left: 16px;
}

.button {
    padding: 0 !important;
    min-width: 0 !important;
    width: 42px;
}

.button span {
    margin-right: 0;
}

.buttonRow {
    flex-wrap: wrap;
}

.buttonRow .custom {
    color:white;
}

@media screen and (max-width: 768px) {
    .row {
        width: 100%;
    }

    .toolbar {
        padding: 0;
    }

    .searchbar {
        width: 80%;
        min-width: unset;
    }

    .buttonRow button {
        width: 100%;
        margin-bottom: 8px;
    }

    .buttonRow button + button {
        margin-left: 0;
    }

    .main {
        padding: 16px;
    }

}
