@value colors: "../../../components/library/style/colors.css";
@value color-purple from colors;

.tableContainer > div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 2fr;
}

.number {
    font-weight: 500;
    color: color-purple;
}
