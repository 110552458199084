@value colors: "../../../components/library/style/colors.css";
@value color-purple, color-purple-dark, color-text-subtle from colors;

.content {
    max-width: 1440px;
    margin: auto;
}

.header {
    width: 100%;
    padding: 24px 24px 0 24px;
    box-sizing: border-box;
    margin: auto;
    background-color: #F8F9FB;
}

.header h1 {
    font-size: 24px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
}

.balance {
    max-width: 425px;
    padding: 16px;
    box-sizing: border-box;

    background: linear-gradient(94.77deg, #5770FF 0%, #4C61D9 101.03%);
    border-radius: 5px;

    color: white;
}

.balance .title {
    font-size: 18px;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 8px;
}

.balance .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.balance .number {
    font-size: 24px;
    font-weight: 500;
    color: white;
    margin: 0 16px 0 0;
}

.custom {
    background-color: white;
    color: color-purple;
}

.custom:hover {
    background-color: color-purple-dark;
    color: white;
}

.navigation {
    margin-top: 24px;
}

.navigation button {
    border-bottom: 2px solid transparent;
    height: 42px;
    color: color-text-subtle;
    transition: .3s;
}

.navigation .active, .navigation button:hover {
    color: color-purple;
    border-bottom: 2px solid color-purple;
}

.logo {
    font-family: 'Fjalla One';
    font-size: 24px;
    margin-top: 0;
    color: black;
    text-decoration: none;
}

.logo:hover {
    color: black;
}

.hide {
    opacity: 0;
}

@media screen and (max-width: 1440px) {
    .content {
        padding: 0 16px;
    }
}

@media screen and (max-width: 768px) {
    .balance button {
        width: 100%;
    }

    .header {
        padding: 16px 0 0 0;
    }

    .balance {
        width: 100%;
    }
}
