@value colors: "../../../../components/library/style/colors.css";
@value color-purple, color-text-subtle from colors;

.table {
    padding: 24px;
    min-height: 45vh;
}

.table > div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 0.25fr;
}

.table > div > p {
    overflow: hidden;
    text-overflow: ellipsis;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    transition: .3s;
}

.button:hover {
    color: color-purple;
    padding-left: 16px;
}

.bold {
    font-weight: 500;
}

.empty {
    height: 35vh;

    display: flex;
    justify-content: center;
    align-items: center;
}

.empty p {
    font-size: 36px;
    font-weight: 500;
    color: color-text-subtle;
}

@media screen and (max-width: 768px) {
    .table > div {
        background-color: #F8F9FB;
        padding: 8px;
        border-radius: 5px;
        grid-template-columns: repeat(1,1fr);
    }

    .table > div > p {
        margin: 4px 0;
    }

    .table > div > a {
       justify-content: flex-end;
    }
}
