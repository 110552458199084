@value colors: "../../library/style/colors.css";
@value color-gray-light from colors;

.header {
    padding-bottom: 16px;
    border-bottom: 1px solid color-gray-light;
}

.header button {
    text-align: left;
    padding: 0;

    display: flex;
    align-items: center;
}

.icon {
    margin-left: 8px;
    font-size: 14px;
}

@media screen and (max-width: 768px) {
    .header {
        display: none !important;
    }
}
